import { joinPaths } from '../utils';
import { UserError, ErrorCode } from '../exceptions';
export function calculatePaths(fetch_cfg) {
    const fetchPaths = [];
    const paths = [];
    fetch_cfg.forEach(function (each_fetch_cfg) {
        const from = each_fetch_cfg.from || '';
        const to_folder = each_fetch_cfg.to_folder || '.';
        const to_file = each_fetch_cfg.to_file;
        const files = each_fetch_cfg.files;
        if (files !== undefined) {
            if (to_file !== undefined) {
                throw new UserError(ErrorCode.BAD_CONFIG, `Cannot use 'to_file' and 'files' parameters together!`);
            }
            for (const each_f of files) {
                const each_fetch_path = joinPaths([from, each_f]);
                fetchPaths.push(each_fetch_path);
                const each_path = joinPaths([to_folder, each_f]);
                paths.push(each_path);
            }
        }
        else {
            fetchPaths.push(from);
            const filename = to_file || from.split('/').pop();
            if (filename === '') {
                throw new UserError(ErrorCode.BAD_CONFIG, `Couldn't determine the filename from the path ${from}, please supply 'to_file' parameter.`);
            }
            else {
                paths.push(joinPaths([to_folder, filename]));
            }
        }
    });
    return [paths, fetchPaths];
}
