import toml from '../src/toml';
import { getLogger } from './logger';
import { version } from './runtime';
import { getAttribute, readTextFromPath, htmlDecode } from './utils';
import { UserError, ErrorCode } from "./exceptions";
const logger = getLogger('py-config');
const allKeys = {
    string: ['name', 'description', 'version', 'type', 'author_name', 'author_email', 'license'],
    number: ['schema_version'],
    array: ['runtimes', 'packages', 'fetch', 'plugins'],
};
export const defaultConfig = {
    schema_version: 1,
    type: 'app',
    runtimes: [
        {
            src: 'https://cdn.jsdelivr.net/pyodide/v0.21.3/full/pyodide.js',
            name: 'pyodide-0.21.3',
            lang: 'python',
        },
    ],
    packages: [],
    fetch: [],
    plugins: [],
};
export function loadConfigFromElement(el) {
    let srcConfig;
    let inlineConfig;
    if (el === null) {
        srcConfig = {};
        inlineConfig = {};
    }
    else {
        const configType = getAttribute(el, 'type') || 'toml';
        srcConfig = extractFromSrc(el, configType);
        inlineConfig = extractFromInline(el, configType);
    }
    srcConfig = mergeConfig(srcConfig, defaultConfig);
    const result = mergeConfig(inlineConfig, srcConfig);
    result.pyscript = {
        version: version,
        time: new Date().toISOString(),
    };
    return result;
}
function extractFromSrc(el, configType) {
    const src = getAttribute(el, 'src');
    if (src) {
        logger.info('loading ', src);
        return validateConfig(readTextFromPath(src), configType);
    }
    return {};
}
function extractFromInline(el, configType) {
    if (el.innerHTML !== '') {
        logger.info('loading <py-config> content');
        return validateConfig(htmlDecode(el.innerHTML), configType);
    }
    return {};
}
function fillUserData(inputConfig, resultConfig) {
    for (const key in inputConfig) {
        // fill in all extra keys ignored by the validator
        if (!(key in defaultConfig)) {
            resultConfig[key] = inputConfig[key];
        }
    }
    return resultConfig;
}
function mergeConfig(inlineConfig, externalConfig) {
    if (Object.keys(inlineConfig).length === 0 && Object.keys(externalConfig).length === 0) {
        return defaultConfig;
    }
    else if (Object.keys(inlineConfig).length === 0) {
        return externalConfig;
    }
    else if (Object.keys(externalConfig).length === 0) {
        return inlineConfig;
    }
    else {
        let merged = {};
        for (const keyType in allKeys) {
            const keys = allKeys[keyType];
            keys.forEach(function (item) {
                if (keyType === 'boolean') {
                    merged[item] =
                        typeof inlineConfig[item] !== 'undefined' ? inlineConfig[item] : externalConfig[item];
                }
                else {
                    merged[item] = inlineConfig[item] || externalConfig[item];
                }
            });
        }
        // fill extra keys from external first
        // they will be overridden by inline if extra keys also clash
        merged = fillUserData(externalConfig, merged);
        merged = fillUserData(inlineConfig, merged);
        return merged;
    }
}
function parseConfig(configText, configType = 'toml') {
    let config;
    if (configType === 'toml') {
        try {
            // TOML parser is soft and can parse even JSON strings, this additional check prevents it.
            if (configText.trim()[0] === '{') {
                throw new UserError(ErrorCode.BAD_CONFIG, `The config supplied: ${configText} is an invalid TOML and cannot be parsed`);
            }
            config = toml.parse(configText);
        }
        catch (err) {
            const errMessage = err.toString();
            throw new UserError(ErrorCode.BAD_CONFIG, `The config supplied: ${configText} is an invalid TOML and cannot be parsed: ${errMessage}`);
        }
    }
    else if (configType === 'json') {
        try {
            config = JSON.parse(configText);
        }
        catch (err) {
            const errMessage = err.toString();
            throw new UserError(ErrorCode.BAD_CONFIG, `The config supplied: ${configText} is an invalid JSON and cannot be parsed: ${errMessage}`);
        }
    }
    else {
        throw new UserError(ErrorCode.BAD_CONFIG, `The type of config supplied '${configType}' is not supported, supported values are ["toml", "json"]`);
    }
    return config;
}
function validateConfig(configText, configType = 'toml') {
    const config = parseConfig(configText, configType);
    const finalConfig = {};
    for (const keyType in allKeys) {
        const keys = allKeys[keyType];
        keys.forEach(function (item) {
            if (validateParamInConfig(item, keyType, config)) {
                if (item === 'runtimes') {
                    finalConfig[item] = [];
                    const runtimes = config[item];
                    runtimes.forEach(function (eachRuntime) {
                        const runtimeConfig = {};
                        for (const eachRuntimeParam in eachRuntime) {
                            if (validateParamInConfig(eachRuntimeParam, 'string', eachRuntime)) {
                                runtimeConfig[eachRuntimeParam] = eachRuntime[eachRuntimeParam];
                            }
                        }
                        finalConfig[item].push(runtimeConfig);
                    });
                }
                else if (item === 'fetch') {
                    finalConfig[item] = [];
                    const fetchList = config[item];
                    fetchList.forEach(function (eachFetch) {
                        const eachFetchConfig = {};
                        for (const eachFetchConfigParam in eachFetch) {
                            const targetType = eachFetchConfigParam === 'files' ? 'array' : 'string';
                            if (validateParamInConfig(eachFetchConfigParam, targetType, eachFetch)) {
                                eachFetchConfig[eachFetchConfigParam] = eachFetch[eachFetchConfigParam];
                            }
                        }
                        finalConfig[item].push(eachFetchConfig);
                    });
                }
                else {
                    finalConfig[item] = config[item];
                }
            }
        });
    }
    return fillUserData(config, finalConfig);
}
function validateParamInConfig(paramName, paramType, config) {
    if (paramName in config) {
        return paramType === 'array' ? Array.isArray(config[paramName]) : typeof config[paramName] === paramType;
    }
    return false;
}
