import { getLogger } from './logger';
const logger = getLogger('pyscript/runtime');
// VERSION
// Version number of release
export const version = '2022.12.1';
/*
Runtime class is a super class that all different runtimes must respect
and adhere to.

Currently, the only runtime available is Pyodide as indicated by the
`RuntimeInterpreter` type above. This serves as a Union of types of
different runtimes/interpreters which will be added in near future.

The class has abstract methods available which each runtime is supposed
to implement.

Methods available handle loading of the interpreter, initialization,
running code, loading and installation of packages, loading from files etc.

For an example implementation, refer to the `PyodideRuntime` class
in `pyodide.ts`
*/
export class Runtime extends Object {
    constructor(config) {
        super();
        this.config = config;
    }
    /**
     * Same as run, but Python exceptions are not propagated: instead, they
     * are logged to the console.
     *
     * This is a bad API and should be killed/refactored/changed eventually,
     * but for now we have code which relies on it.
     * */
    runButDontRaise(code) {
        let result;
        try {
            result = this.run(code);
        }
        catch (error) {
            logger.error('Error:', error);
        }
        return result;
    }
}
